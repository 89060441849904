<template>
<div class="grid-x">
    <div class="cell" v-if="activelink >= 0" style="padding-bottom: 5rem;">
	<ul  class="vertical menu drilldown" data-drilldown>
        <li v-if="masterfolder == 189 || masterfolder == 397" class="homelink"><a href="/">Main Site</a></li>
        <li class="back" v-if="mainmenu[activelink] && mainmenu[activelink].back_link"><a href="#"  @click="getmenu(mainmenu[activelink].back_link)">Back</a></li>
        <li v-if="mainmenu[activelink]"><a :href="mainmenu[activelink].url"><span style="font-weight:bold;" v-html="mainmenu[activelink].title"></span></a></li>
        <template v-for="(menuitem, index) in mainmenu[activelink].items">
            <li v-if="mainmenu[activelink].items[index].is_parent == 0"  :key="index">
                <a :href="menuitem.url"><span v-html="menuitem.title"></span></a>
            </li>
            <li class="parent" v-else  :key="index">
                <a href="#" @click="getmenu(menuitem.id)"><span v-html="menuitem.title"></span></a>
            </li>
        </template>
	</ul>
    </div>
</div>
</template>
<style scoped>
li.parent {
    position: relative;
}
li.back a {
    padding-left: 1.5rem !important;
    position: relative;
}
li.parent::after{
    position: absolute;
    right:0;
    top: 10px;
    content: url(/assets/images/icons/AF_menu_arrow_right.svg);
    border-style: none;
}
li.back a::before{
    position: absolute;
    left:0;
    top: 14px;
    content: url(/assets/images/icons/AF_menu_arrow_left.svg);
    border-style: none;
}
</style>
<script>``
	export default {
        props: ['masterfolder'],
        data: function () {
            return {
                mainmenu : [],
                activelink: -1
            }
        },
        methods: {
            getmenu: function (i) {
                this.activelink = -1;
                for(var c = 0; c < this.mainmenu.length; c++) {
                    if(this.mainmenu[c].id == i) {
                        this.activelink = c;
                    }
                }
                var vm = this;
                if(this.activelink == -1) {
                    RendiliCore.Json.Get({
                        url: '/alternative/mobilemenu/getmenu/' + this.masterfolder + '/' + i
                    })
                    .then(function (data) {
                        vm.mainmenu.push(data);
                        vm.activelink = vm.mainmenu.length - 1;
                    }); 
                } 
                
            },

        },
        created: function () {
            var vm = this;
            RendiliCore.Json.Get({
                url: '/alternative/mobilemenu/getmenu/' + this.masterfolder + '/' + this.masterfolder
            })
            .then(function (data) {
                vm.mainmenu.push(data);
                vm.activelink = 0;
            });        
        },
	}
</script>