<template>
    <ul class="vertical menu">
        <li v-for="(child, index) in value">
            <a :href="child.url" @mouseover="setactivelink(index)" @mouseout="removeactivelink(index)" v-bind:class="[{current : activelink == index}, {parent: child.grandchildren && child.grandchildren.length > 0}]">
                <span v-if="child.icon" style="display: inline-block; width: 45px;"><img :src="child.icon" :alt="child.title" /></span>
                {{ child.title }}
            </a>
        </li>
    </ul>
</template>
<script>
	export default {
		props: ['value'],
        data: function () {
            return {
                activelink: null
            }
        },
        methods: {
            setactivelink : function(event) {
                this.activelink = event;
                this.$emit('setactivelink', event);
            },
            removeactivelink: function(event) {
                this.activelink = null;
                this.$emit('setactivelink', event);
            }
        }
	}
</script>