window.browserUpdate = require('browser-update');

window.$ = window.jQuery = require('jquery');
require('foundation-sites');
//require('motion-ui');
require('slick-carousel');

import RendiliCore from './../../../rendili-core/scripts/rendili-core.js';
window.RendiliCore = RendiliCore;
//import Vue from 'vue';
//window.Vue = Vue;
import { Vue2 } from 'vue-demi';
window.Vue = Vue2;
import Vuex from 'vuex';
window.Vuex = Vuex;
import * as VuexMapFields from 'vuex-map-fields';
window.VuexMapFields = VuexMapFields;

import { VueRecaptcha } from 'vue-recaptcha';
window.VueRecaptcha = VueRecaptcha;

import rendiliDatePicker from './components/date';
Vue.component('datepicker', rendiliDatePicker);

import rendiliFileUpload from './components/fileupload';
Vue.component('filer', rendiliFileUpload);

import vueField from './components/field';
Vue.component('field', vueField);

import Pagination from 'vue-pagination-2';
Vue.component('pagination', Pagination);

import SiteSearch from './components/site-search';
Vue.component('site-search', SiteSearch);

import RetailerSearch from './components/retailer-search';
Vue.component('retailer-search', RetailerSearch);

import MegaMenu from './components/mega-menu';
Vue.component('mega-menu', MegaMenu);

import MapResults from './components/map-results';
Vue.component('map-results', MapResults);

import validationMixin from './mixins/validation.js';
window.ValidationMixin = validationMixin;

import MobileMenu from './components/mobile-menu';
Vue.component('mobile-menu', MobileMenu);

import Modal from './components/modal';
Vue.component('modal', Modal);

import { createApp, ref } from 'vue-demi';
window.createApp = createApp;
window.ref = ref;