<template>
	<div class="grid-x" id="retailer-search">
		<div class="cell" v-show="showResults">
			<div class="grid-x searchbar">
				<div class="large-4 cell">
					<input ref="autocomplete2" placeholder="Search for Postcode, Town or City" style="margin-bottom:0;" onfocus="value = ''" type="text" />
				</div>
				<div class="large-8 text-right cell">
					<ul class="menu map-key align-right">
                        <ul class="menu map-key align-right">
                            <li><img src="/assets/images/map_markers/play_partner_icon.svg" alt="Alternative Play Partner Partner Marker" />Play Partner</li>
                            <li><img src="/assets/images/map_markers/premium_retailer_icon.svg" alt="Alternative Premium Retail Partner Marker" style="padding-top:3px" />Premium Retailer</li>
                            <li><img src="/assets/images/map_markers/retail_partner_icon.svg" alt="Alternative Retail Partner Marker" />Retail Partner</li>
                            <li><img src="/assets/images/map_markers/retailer_info.svg" alt="More information about our retailers" title="About our Retailers"  style="cursor:pointer" v-on:click="showretailerinfo = 1" /></li>
                        </ul>
					</ul>
				</div>
			</div>                
			<div id="retailer-info">
                    <transition name="fade">
                        <div v-if="showretailerinfo">
							<button class="closeinfo" @click="showretailerinfo = 0"><img src="/assets/images/icons/green_close.svg" alt="close retailer information" /></button>
							<div class="grid-x grid-padding-x inner">
								<div class="cell"></div>
								<div class="cell">
									<h2>Our retailers explained</h2>
								</div>
								<div class="medium-10 medium-offset-1 xlarge-8 xlarge-offset-2 cell">
									<p class="text-center">{{ retailerInfo.intro }}</p>
								</div> 
								<div class="medium-4 cell" v-for="(item, index) in retailerInfo.items">
									<div class="grid-x grid-padding-x">
										<div class="cell"><h3><img :src="retailerInfo.items[index].icon" />{{ retailerInfo.items[index].name }}</h3></div>
										<div class="cell"><span v-html="retailerInfo.items[index].text"></span></div>
									</div>
								</div>  
								<div class="cell text-center"><a class="chooseretailer" @click.prevent="scrollToMap">Choose your retailer on the map below</a></div>
							</div>
                        </div>
                    </transition>
                </div>
			<map-results v-if="showResults" ref="mapresults" :results="results" :google="google" :place="placeName" :is-ireland="isIreland" :action-name="actionName" @select-retailer="selectRetailer"></map-results>
		</div>
		<div v-show="!showResults" class="small-10 small-offset-1 medium-12 medium-offset-0 large-10 large-offset-1 cell text-center">
			<div class="grid-x grid-margin-x">
				<div class="medium-6 cell searchblock">
					<button type="button" class="button expanded find" @click="locate" style="margin-bottom:0;"><span>Use my current location</span></button>
				</div>
				<div class="medium-6 cell searchblock">
					<div class="grid-x grid-padding-x">
						<div class="small-8 cell">
	                        <input ref="autocomplete" placeholder="Search for Postcode, Town or City" style="margin-bottom:0;" onfocus="value = ''" type="text" />
						</div>
						<div class="small-4 cell"><button type="button" class="button expanded" @click="geocode" style="margin-bottom:0;">Search</button></div>
					</div>
				</div>
				<div class="medium-6 medium-offset-3 cell">
					<form>
						<div class="holder">
						<p>
						<select class="greenarrow" @change="ireland">
							<option value="" class="text-center">Search for retailers outside the UK</option>
							<option value="ireland" >Republic of Ireland</option>
						</select>
						</p>
						</div>
					</form>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import mapResults from './map-results';

	export default {
		props: {
			actionName: {},
			clickCollectOnly: {
				default: false
			},
			scrollView: {
				default: true
			}
		},
		components: {
			mapResults
		},
		data: function() {
			return {
				showResults: false,
				place: null,
				placeName: null,
				placeCoords: null,
				results: [],
				isIreland: false,
				county: null,
				google: null,
				retailerInfo: [],
				showretailerinfo: 0,
				autocomplete: null,
				autocomplete2: null
			};
		},
		created: function() {
			var self = this;
			fetch('/alternative/retailer/info', {
  
			}).then(function (response) {
				return response.json();
			}).then(function (data) {
				self.retailerInfo = data;
			});
			const loader = new google.maps.plugins.loader.Loader({
				apiKey: gmap_api_key,
				version: 'weekly',
				libraries: ['places']
			}).load()
			.then(() => {
				self.google = google;
				this.autocomplete = new google.maps.places.Autocomplete(
					(this.$refs.autocomplete),
					{
						types: ['geocode'],
						componentRestrictions: {country: [ 'gb', 'ie', 'je', 'gg', 'im' ]}
					}
				);
				this.autocomplete.addListener('place_changed', () => {
					let place = this.autocomplete.getPlace();
					self.placeName = place.formatted_address;
					let lat = place.geometry.location.lat();
					let lon = place.geometry.location.lng();
					self.search(lat, lon);
				});				
				this.autocomplete2 = new google.maps.places.Autocomplete(
					(this.$refs.autocomplete2),
					{
						types: ['geocode'],
						componentRestrictions: {country: [ 'gb', 'ie', 'je', 'gg', 'im' ]}
					}
				);
				this.autocomplete2.addListener('place_changed', () => {
					let place = this.autocomplete2.getPlace();
					self.placeName = place.formatted_address;
					let lat = place.geometry.location.lat();
					let lon = place.geometry.location.lng();
					self.search(lat, lon);
				});		
			});
		},
		methods: {
			locate: function() {
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(this.locateComplete);
				}
			},
			locateComplete: function(position) {
				var self = this;
				var geocoder = new this.google.maps.Geocoder();
				geocoder.geocode ({ location: { lat: position.coords.latitude, lng: position.coords.longitude } }, function(results, status) {
					self.placeName = results[0].formatted_address;
				});
				this.search(position.coords.latitude, position.coords.longitude);
			},
			geocode: function(place = null) {
                var self = this;
                var geocoder = new this.google.maps.Geocoder();
				if (place != null) {
					this.place = place;
				}
				geocoder.geocode ({ address: this.place, region: 'gb' }, function(results, status) {
                    if (results.length == 0) {
                    } else if (results.length == 1) {
						if (place != null) {
							self.$emit('geocode', {
								place: place,
								placeName: results[0].formatted_address,
								lat: results[0].geometry.location.lat(),
								lng: results[0].geometry.location.lng()
							});
						}
						self.placeName = results[0].formatted_address;
						self.search(results[0].geometry.location.lat(), results[0].geometry.location.lng());
					} else {
                        var items = [];
                        var ukbounds = new self.google.maps.LatLngBounds();
                        ukbounds.extend(new self.google.maps.LatLng(49.728740, -11.753427));
                        ukbounds.extend(new self.google.maps.LatLng(61.230076, 2.736077));
                        for (var i = 0; i < results.length; i++) {
                            if (ukbounds.contains(results[i].geometry.location)) {
                                items.push(results[i]);
                            }
						}
                        if (items.length == 0) {
                        } else if (items.length == 1) {
							if (place != null) {
								self.$emit('geocode', {
									place: place,
									placeName: items[0].formatted_address,
									lat: items[0].geometry.location.lat(),
									lng: items[0].geometry.location.lng()
								});
							}
                            self.placeName = items[0].formatted_address;
    						self.search(items[0].geometry.location.lat(), results[0].geometry.location.lng());
                        } else {
							if (place != null) {
								self.$emit('geocode', {
									place: place,
									placeName: items[0].formatted_address,
									lat: items[0].geometry.location.lat(),
									lng: items[0].geometry.location.lng()
								});
							}
							self.placeName = items[0].formatted_address;
    						self.search(items[0].geometry.location.lat(), results[0].geometry.location.lng());
                        }
					}
                });
			},
			search: function(lat, lng) {
				var self = this;
				this.placeCoords = new this.google.maps.LatLng(lat, lng);			
				fetch('/alternative/map', {
					method: 'POST', body: JSON.stringify({ lat: lat, lng: lng, distance: 100, click_collect: self.clickCollectOnly === true || self.clickCollectOnly === 'true', click_collect_carpet: self.clickCollectOnly === 'carpet' })
				}).then(function (response) {
					return response.json();
				}).then(function (data) {
					self.results = data.results;
					self.showResults = true;
				}).then(function() {
					if (self.scrollView) {
                    	document.getElementById('retailer-search').scrollIntoView();
					}
				});
			},
            ireland: function() {
                this.isIreland = true;
                this.county = null;
                var self = this;
                fetch('/alternative/map/ireland', {
                    method: 'POST', body: JSON.stringify({ county: this.county })
                }).then(function (response) {
                    return response.json();
                }).then(function (data) {
                    self.results = data.results;
                    self.showResults = true;
                }).then(function() {
					if (self.scrollView) {
                    	document.getElementById('retailer-search').scrollIntoView();
					}
                });
            },
			selectRetailer: function(marker) {
				this.$emit('select-retailer', marker);
			}
		}
	}
</script>
<style>
	.pac-logo:after {
		display: none !important;
	}
</style>