import { isNumeric } from "jquery";
import { 
	isValid, 
	isExpirationDateValid, 
	isSecurityCodeValid, 
	getCreditCardNameByNumber 
  } from 'creditcard.js';

export default {
	methods: {
		validateRequired: function() {
			if (this.validationParameters.required == true && (this.value == null || this.value.length == 0)) {
				return false;
			}
			return true;
		},
		validateRange: function() {
			//numeric and within range set by min and max on element
			if (!this.validationParameters.required == true && (this.value == '' || this.value == null)) {
				return true;
			}
	
			var value = parseInt(this.value);
	
			if (isNaN(value)) {
				return false;
			}
			if (value < this.data.min || value > this.data.max) {
				return false;
			}
	
		},
		validateLength: function() {
			if (!this.validationParameters.required == true && (this.value == '' || this.value == null)) {
				return true;
			}
	
			var length = this.value.length;

			if (length < this.data.min || length > this.data.max) {
				return false;
			}
		},
		validateCheckEmail: function() {
			var check = /^[a-zA-Z0-9_\-\.]+@[a-zA-Z0-9_\-\.]+\.[a-zA-Z0-9_\-\.]+$/;
			var result = this.value.match(check);
			if (result == null) {
				return "Not a valid email address";
			} else {
				return false;
			}
		},
		validateCheckPhone: function() {
			var check = /^[0|\+][0-9 ]+$/;
			var result = this.value.match(check);
			if (result == null) {
				return 'Not a valid phone number';
			} else {
				return false;
			}
		},
		validateCheckPostcode: function() {
			var check = /^[A-Za-z]{1,2}[0-9][A-Za-z0-9]? ?[0-9]{1}[A-Za-z]{2}$/;
			var result = this.value.match(check);
			if (result == null && this.value != '412') {
				return 'Not a valid UK Postcode format';
			} else {
				return false;
			}
		},
		validateCheckMailName: function() {
			var check = /^[a-zA-Z0-9_\-\.]+$/;
			var result = this.value.match(check);
			if (result == null) {
				return 'New Email Address can only contain alphanumeric, underscores, dashes or dots';
			} else {
				return false;
			}
		},
		validateCheckSubdomain: function() {
			var check = /^[a-zA-Z0-9_\-]+$/;
			var result = this.value.match(check);
			if (result == null) {
				return 'Subdomain can only contain alphanumeric, underscores or dashes';
			} else {
				return false;
			}
		},
		validateAlphaOnly: function() {
			var check = /^[a-zA-Z]+$/;
			var result = this.value.match(check);
			if (result == null) {
				return 'Contains non alphabetic characters';
			} else {
				return false;
			}
		},
		validateCheckUsername: function() {
			var check = /^[a-zA-Z0-9_\-]+$/;
			var result = this.value.match(check);
			if (result == null) {
				return 'can only contain alphanumeric, underscores or dashes';
			} else {
				return false;
			}
		},
		validateCheckColour: function() {
			var check = /^#[A-Fa-f0-9]{6}$/;
			var result = this.value.match(check);
			if (result == null) {
				return 'Not a valid CSS Colour';
			} else {
				return false;
			}
		},
		validateCheckCreditCard: function() {
			if (isValid(this.value)) {
				return false;
			} else {
				return 'invalid card number';
			}
		},
		validateCheckExpiryDate: function() {
			if (this.value.length != 4) {
				return 'Not a valid expiry date';
			}
			var month = this.value.substr(0,2);
			var year = this.value.substr(2,2);
			var curdate = new Date();
			var curmonth = curdate.getMonth() + 1;
			var curyear = curdate.getFullYear() - 2000;
			if (month < 1 || month > 12 || year < curyear || (year == curyear && month < curmonth)) {
				return 'Not a valid expiry date';
			} else {
				return false;
			}
		},
		validateCheckMatch: function() {
			if (this.value != '' && this.value != null && this.value != this.comparison) {
				return 'Does not match';
			} else {
				return false;
			}
		},
		validate: function() {
			var valid = true;
			var validation_messages = [];
			if (typeof this.validationParameters == 'undefined') {
				this.isvalid = true;
			} else {
				if (this.validationParameters.required == true && !this.validateRequired()) {
					valid = false;
					validation_messages.push('required field');
				}
				if (this.value != null && this.value.length > 0) {
					if (typeof this.validationParameters.min != 'undefined' && this.value.length < this.validationParameters.min) {
						valid = false;
						validation_messages.push('minimum length ' + this.validationParameters.min);
					}
					if (typeof this.validationParameters.max != 'undefined' && this.value.length > this.validationParameters.max) {
						valid = false;
						validation_messages.push('maximum length ' + this.validationParameters.max);
					}
					if (typeof this.validationParameters.numeric != 'undefined' && ((this.value - 0) == this.value && (''+this.value).trim().length > 0)) {
						valid = false;
						validation_messages.push('is not a number');
					}
					if (typeof this.validationParameters.alpha != 'undefined') {
						var result = this.validateAlphaOnly();
						if (result != false) {
							valid = false;
							validation_messages.push(result);
						}
					}
					if (typeof this.validationParameters.function != 'undefined') {
						var result = this['validate' + this.validationParameters.function]();

						if (result != false) {
							valid = false;
							validation_messages.push(result);
						}
					}
					if (typeof this.validationParameters.allowedValues != 'undefined') {
						console.log('check', this.value, 'from', this.validationParameters.allowedValues, 'yields',$.inArray(this.value, this.validationParameters.allowedValues));
						if ($.inArray(this.value, this.validationParameters.allowedValues) == -1) {
							valid = false;
							validation_messages.push('is not a valid value');
						}
					}
				}
				this.isvalid = valid;
				this.validation_messages = validation_messages;
			}
			return this.isvalid;
		},
		setClean: function() {

		},
		removeSpaces: function() {
			var newval = this.value.replace(/ /g, "").toLowerCase();
			if (this.value != newval) {
				this.value = newval;
			}
		},
		replaceSpaces: function() {
			var newval = this.value.replace(/[\s-_]+/g, "-").toLowerCase();
			newval = newval.replace(/[^/A-Za-z0-9\s-]/g, "");
			if (this.value != newval) {
				this.value = newval;
			}
		}
	}
}