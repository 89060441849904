<template>
	<div class="grid-x">
		<div class="cell value">
			<datepicker-component v-model="chosendate" format="dd/MM/yyyy"></datepicker-component>
		</div>
		<!--
		<div class="small-5 cell">
			<button type="button" class="button clearbtn" @click="clear">Clear</button>
		</div>-->
	</div>
</template>
<script>
	import vuejsDatepicker from 'vuejs-datepicker';

	export default {
		components: {
  			'datepicker-component': vuejsDatepicker
  		},
		props: ['value'],
		data: function(){
			return {
				chosendate: ''
			}
		},
		/*
		computed: {
			date: {
				get: function() {
					return (this.chosendate == null || this.chosendate == '' || this.chosendate == '0000-00-00') ? null : new Date(this.chosendate).toDateString();
				},
				set: function(chosendate) {
					if(chosendate) {
						this.chosendate = this.chosendate.toISOString().slice(0,10);
					} else {
						this.chosendate = ''
					}
				}
			},
			displayDate: function() {
				return (this.chosendate == '' || this.chosendate == '0000-00-00') ? 'Not set' : new Date(this.chosendate).toDateString();
			}		
		},*/
		methods: {
			clear: function() {
				this.chosendate = '';
			}
		},
		mounted: function() {
			this.chosendate = this.value;
		},
		watch: {
			chosendate: function() {
				this.$emit('setdate', (this.chosendate == '' || this.chosendate == '0000-00-00') ? '' : new Date(this.chosendate).toDateString());
			}
		},
	};
</script>
<style lang="scss" scoped>

	.vdp-datepicker {
		border: solid 1px #D3D3D4;
		border-radius: 4px;
		height: 46px;
		margin-bottom: 0;
		background: #ffffff;
	}
	.button.clearbtn {
		margin: 0 0 0 0.5rem !important;
		height: 46px; 
	}
</style>