<template>
    <transition name="modal-transition" appear>
        <div class="modal-mask">
            <div class="alternativeModal modal-wrapper">
                <div :class="template + ' modal-container'">
                    <div class="modal-header">
                        <slot name="header">
                        </slot>
                    </div>
                    <div class="modal-body">
                        <slot name="body">
                        </slot>
                    </div>
                    <div class="modal-footer">
                        <slot name="footer">
                            <button class="modal-close-button" @click="$emit('close')">OK</button>
                        </slot>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
export default {
    props: {
        template: {
            default: 'standard'
        }
    },
};
</script>
<style lang="scss" scoped>
    .modal-mask {
        position: fixed;
        z-index: 9998;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        display: table;
        transition: opacity 0.3s ease;
        .modal-wrapper {
            display: table-cell;
            vertical-align: middle;
            .modal-container {
                display: block;
                &.standard {
                    width: 300px;
                    margin: 0px auto;
                    padding: 20px 30px;
                    background-color: #fff;
                    border-radius: 2px;
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
                    .modal-body {
                        margin: 20px 0;
                    }
                }
                transition: all 0.3s ease;

                .modal-footer {
                    text-align: center;
                }
            }
        }
    }

    .modal-transition-enter {
        opacity: 0;
        .modal-container {
            -webkit-transform: scale(0.1);
            transform: scale(0.1);
        }
    }
    .modal-transition-leave-to {
        opacity: 0;
        .modal-container {
            -webkit-transform: scale(1.1);
            transform: scale(1.1);
        }
    }
    .modal-transition-enter-active, .modal-transition-leave-active {
    transition: all .5s;
    }
</style>