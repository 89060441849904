<template>
    <div>
        <div class="grid-x map-container">
            <div class="medium-7 large-8 cell">
                <div id="map"></div>
            </div>
            <div id="results" class="medium-5 large-4 cell">
                <div v-if="selected == null">
                    <div v-if="isIreland" class="grid-container">
                        <h2>Showing retailers in the Republic of Ireland</h2>
                        <p>Please select your retailer by clicking on the marker on the map.</p>
                    </div>
                    <div v-else>
                        <p class="info">Showing nearest <span v-if="results.length == 1">retailer</span><span v-else><b>{{ results.length }}</b> retailers</span> to <b>{{ place }}</b></p>
                        <div class="list-container">
                            <div class="list grid-x">
                                <div v-for="(retailer, index) in results" v-bind:key="index" class="cell">
                                    <div class="grid-x" :class="'entry ' + retailer.type">
                                        <div class="small-5 medium-12 large-4 cell text-center">
                                        <figure>
                                            <img :src="'/assets/images/map_markers/'+ retailer.type +'.svg'" />
                                            <figcaption>
                                                <p>{{ retailer.typename }}</p>
                                                <p class="distance">{{ retailer.distance }} miles</p>
                                            </figcaption>
                                        </figure>
                                        </div>
                                        <div class="small-7 medium-12 large-7 large-offset-1 cell">
                                            <h3 v-html="retailer.name" @click="selectRetailer(retailer)"></h3>
                                            <p class="addr" v-html="retailer.address"></p>
                                            <p v-html="retailer.postcode"></p>
                                            <p><b>t</b> {{ retailer.phone }}</p>
                                            <p>
                                                <slot name="retailer-action" :retailer="retailer" :actionName="actionName">
                                                    <button type="button" class="button" @click="selectRetailer(retailer)">{{ actionName }}</button>
                                                </slot>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="grid-x grid-padding-x" :class="'entry ' + selected.type">
                        <div class="small-4 medium-12 large-4 cell text-center">
                        <figure>
                            <img :src="'/assets/images/map_markers/'+ selected.type +'.svg'" />
                            <figcaption>
                                <p>{{ selected.typename }}</p>
                                <p class="distance">{{ selected.distance }} miles</p>
                            </figcaption>
                        </figure>
                        </div>
                        <div class="small-8 medium-12 large-7 large-offset-1 cell">
                            <h3 v-html="selected.name" @click="selectRetailer(selected)"></h3>
                            <p class="addr" v-html="selected.address"></p>
                            <p v-html="selected.postcode"></p>
                            <p><b>t</b> {{ selected.phone }}</p>
                            <p>
                                <slot name="retailer-action" :retailer="selected" :actionName="actionName">
                                    <button type="button" class="button" @click="selectRetailer(selected)">{{ actionName }}</button>
                                </slot>
                            </p>
                            <button type="button" @click="closeEntry" style="text-decoration:underline; cursor:pointer;">Back to list</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
	import MarkerClusterer from '@googlemaps/markerclustererplus';

	export default {
        props: ['actionName', 'place', 'results', 'google', 'isIreland'],
        data: function() {
            return {
                initial: {
					center: new google.maps.LatLng(53.956086,-4.921875),
                	zoom: 5
				},
                map: null,
                markerGroups: null,
                markers: null,
                markerClusterer: null,
                clusterStyle: null,
                selected: null,
                enquireto: null
            }
        },
        mounted: function() {
            this.map = new google.maps.Map(document.getElementById("map"), {
                center: new google.maps.LatLng(this.initial.center),
                zoom: this.initial.zoom,
                mapTypeControlOptions: {
                    mapTypeIds: [this.google.maps.MapTypeId.ROADMAP, this.google.maps.MapTypeId.HYBRID]
                },
                styles :[{stylers : [{saturation: -100}]}],
                mapTypeId: this.google.maps.MapTypeId.ROADMAP,
            });
            this.google.maps.Map.prototype.markers = new Object();
            this.google.maps.Map.prototype.getMarkers = function() {
                return this.markers;
            };
            this.google.maps.Map.prototype.clearMarkers = function() {
                for(var id in this.markers){
                    this.markers[id].setMap(null);
                }
                
                this.markers = new Object;
            };
            this.google.maps.Marker.prototype._setMap = google.maps.Marker.prototype.setMap;
            this.google.maps.Marker.prototype.setMap = function(map) {
                if (map) {
                    map.markers[this.id] = this;
                }
                this._setMap(map);
            }            
            this.markerGroups = {af_retailer: { name: 'AF RETAILERS', status: true, markers: {} }, retailer: { name: 'retailers', status: true, markers: {} }};
            this.markers = {
                retailer: {
                    url: '/assets/images/map_markers/retailer.svg',
                    size: new google.maps.Size(36,48),
                    scaledSize: new google.maps.Size(36,48),
                    anchor: new google.maps.Point(22,48)
                },
                af_retailer: {
                    url: '/assets/images/map_markers/af_retailer.svg',
                    size: new google.maps.Size(43,39),
                    scaledSize: new google.maps.Size(43,39),
                    anchor: new google.maps.Point(21,39)
                },
                play_partner: {
                    url: '/assets/images/map_markers/play_partner.svg',
                    size: new google.maps.Size(68,65),
                    scaledSize: new google.maps.Size(68,65),
                    anchor: new google.maps.Point(34,31)
                }
            }
            this.clusterStyle = [{
                url: '/assets/images/map_markers/cluster.png',
                height: 55,
                width: 56,
                textColor: '#ffffff',
                textSize: 14,
                anchorText: [20,0]
            }, {
                url: '/assets/images/map_markers/cluster.png',
                height: 55,
                width: 56,
                textColor: '#ffffff',
                textSize: 14,
                anchorText: [20,0]
            }, {
                url: '/assets/images/map_markers/cluster.png',
                height: 55,
                width: 56,
                textColor: '#ffffff',
                textSize: 14,
                anchorText: [20,0]
            }];


            this.updateMap();
        },
        methods: {
            updateMap: function() {
                var self = this;
                this.map.clearMarkers();
                if (this.markerClusterer != null) {
                    this.markerClusterer.clearMarkers();
                }

                for(var index = 0; index < this.results.length; index++) {
                    const marker = new this.google.maps.Marker({
                        position: new this.google.maps.LatLng(this.results[index].lat, this.results[index].lng),
                        icon: this.markers[this.results[index].type],
                        animation: this.google.maps.Animation.DROP,
                        title: this.results[index].name,
                        clickable:true,
                        type: this.results[index].type,
                        classType: 'marker',
                        id: this.results[index].id,
                        map: this.map,
                        zIndex: this.results[index].zindex
                    });
                    marker.addListener('click', () => { self.selectEntry(marker) });
                }
                var markerClusterList = [];
                var searchbounds = new this.google.maps.LatLngBounds();

                var count = 0;
                var items = '';
                for (var id in this.map.markers) {
                    //if (this.map.markers[id].type != 'home') {
                    //    markerClusterList.push(this.map.markers[id]);
                        count ++;
                    //}
                    searchbounds.extend(this.map.markers[id].position);
                }
                //this.markerClusterer = new MarkerClusterer(this.map, markerClusterList, { styles: this.clusterStyle, gridSize: 40 });

                if (count > 0) {
                    this.map.fitBounds(searchbounds);
                } else {
                    this.setFullScreen();
                }               
            },
            setFullScreen() {
                this.setMapPosition(this.initial.center, this.initial.zoom);
            },
            fitMapToMarkers: function() {
                var searchbounds = new this.google.maps.LatLngBounds();
                var count = 0;
                for (var id in this.map.markers) {
                    if (typeof this.map.markers[id].position != 'undefined') {
                        searchbounds.extend(this.map.markers[id].position);
                        count ++;
                    }
                }
                if (count > 0) {
                    this.map.fitBounds(searchbounds);
                } else {
                    this.resetMap();
                }               
            },
            resetMap: function() {
                this.setMapPosition(this.initial.center, this.initial.zoom);
            },
            setMapPosition: function(center, zoom) {
                this.map.setCenter(center);
                this.map.setZoom(zoom);
            },
            selectEntry: function(marker) {
                this.setMapPosition(marker.position, 16);
                this.selected = this.results.find(o => o.id == marker.id);
            },
            closeEntry: function(marker) {
                this.fitMapToMarkers();
                this.selected = null;
            },
            selectRetailer: function(marker) {
                this.$emit('select-retailer', marker);
            }
        },
        watch: {
            results: function() {
                this.updateMap();
            }
        }
    }
</script>